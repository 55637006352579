import defaultPreset from '../presets-thumbnails/default.png';
import labelTopPreset from '../presets-thumbnails/label-top.png';
import labelBottomPreset from '../presets-thumbnails/label-bottom.png';
import { mobilePresets } from './presetsIds';

export const counterDesignPresetsMobile = [
  {
    id: mobilePresets[0].id,
    src: defaultPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: mobilePresets[1].id,
    src: labelTopPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: mobilePresets[2].id,
    src: labelBottomPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
];
